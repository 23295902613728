<template>
	<div :class="{hidden: !active}">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'BlTab',
	props: {
		name: {},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		disabled() {
			this.blTabUpdate()
		}
	},
	inject: ['blTabsRegister', 'blTabsUnregister', 'blTabUpdate'],
	data() {
		return {
			active: false,
			index: null
		}
	},
	mounted() {
		this.blTabsRegister(this)
	},
	beforeUnmount() {
		this.blTabsUnregister(this)
	}
}
</script>

<style scoped lang="scss">
	.hidden {
		overflow: hidden;
		width: 0;
		height: 0;
		position: relative;
	}
</style>