<template>
	<div :class="{errored: field.isErrored() && field.getTouched(), focused: focused && !(field.isErrored() && field.getTouched())}" class="blFormFieldRichText">
		<label>{{ field.label }}</label>
		<div class="inputContainer" @click="$refs.wysiwyg.focus()">
			<BlWysiwyg style="flex: 1;" v-model="model" @change="setFieldValue()" @blur="focused = false; field.setTouched()" @focus="focused = true" ref="wysiwyg" />
			<button @click="toggleRecord()" class="bl-icon-button microphone" :class="{recording: recording}" type="button">mic</button>
			<div v-if="currentRequest" class="requestIndicator"><div></div></div>
			<div v-if="recording" class="recordingIndicator">
				<div>
					<div :style="{height: volumeScale + '%', borderRadius: (100 - volumeScale) + '%', opacity: (volumeScale / 200) + 0.5}"></div>
				</div>
			</div>
		</div>
		<div class="audioFiles">
			<div v-for="key in Object.keys(audioFiles)" :key="key">
				<icon>volume_up</icon>
				<button class="bl-icon-button" type="button" @click="removeAudioFile(key)">delete</button>
				{{ $t('Audio') }} {{ parseInt(key) + 1 }}
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog, ViewServices, EventEmitter } from 'InterfaceBundle'
import { Api } from 'ModelBundle'

export default {
	name: 'BlFormFieldVoice',
	props: ['field'],
	data() {
		return {
			focused: false,
			recording: false,
			recorder: null,
			volumeScale: 60,
			model: this.field.value,
			recognition: null,
			audioFiles: [],
			recognitionResults: false,
			currentRequest: false
		}
	},
	created() {
		this.stopRecordingEvent = new EventEmitter()
		this.setModelValue()
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => this.setModelValue())
	},
	unmounted() {
		if(this.recording && this.recorder) this.recorder.stop()
	},
	methods: {
		toggleRecord() {
			if(this.recording) this.stopRecording()
			else this.startRecording()
		},
		startRecording() {
			if(!this.recognition) this.initializeRecognition()
			if(this.recognition) this.recognition.start()
			this.onBeforeSubmitSub = this.field.root.form.onBeforeSubmit.subscribe(val => val.events.push(this.stopRecording()))
			document.activeElement.blur()
			navigator.mediaDevices.getUserMedia({audio:true}).then(stream => {
				this.recognitionResults = false
				this.recorder = new MediaRecorder(stream)
				let audioChunks = []
				this.recorder.ondataavailable = e => {
					audioChunks.push(e.data)
					if(this.recorder.state == 'inactive') {
						const blob = new Blob(audioChunks, {type: 'audio/mpeg'})
						const reader = new FileReader()
						reader.readAsDataURL(blob)
						reader.onloadend = () => {
							this.audioFiles.push(reader.result)
							this.setFieldValue()
							if(!this.recognitionResults) this.mobileResult(reader.result)
						}

						stream.getTracks().forEach(track => track.stop())
						this.recorder = null
						if(this.recognition) this.recognition.stop()
					}
				}
				this.recorder.start()
				this.analyse(stream)
				this.recording = true
				if(this.model) this.model += '<br />'
			}).catch(() => {
				Dialog.alert({title: 'Unable to access the microphone', content: 'Make sure you allowed MixSuite to access the microphone in the browser and that you have a microphone avaliable on your device.'})
			})
		},
		analyse(stream) {
			const context = new AudioContext()
			const analyser = context.createAnalyser()
			const source = context.createMediaStreamSource(stream)
			const dataArr = new Uint8Array(analyser.frequencyBinCount)
			source.connect(analyser)
			const report = () => {
				analyser.getByteFrequencyData(dataArr)
				const volume = Math.floor((Math.max(...dataArr) / 255) * 100)
				this.volumeScale = volume
				setTimeout(() => {
					if(this.recorder) requestAnimationFrame(report)
					else context.close()
				}, 100)
			}
			report()
		},
		initializeRecognition() {
			if(!('webkitSpeechRecognition' in window)) return
			this.recognition = new window.webkitSpeechRecognition()
			this.recognition.continuous = true
			this.recognition.interimResults = true
			this.recognition.lang = ViewServices.interfaceData.userPreferences.language
			let prevTxt = ''
			this.recognition.addEventListener('result', event => {
				if(!this.model) this.model = ''
				this.recognitionResults = true
				this.model = this.model.replace(new RegExp(/<em class="interim">.*<\/em>/), '')
				for (let i = event.resultIndex; i < event.results.length; ++i) {
					if(event.results[i].isFinal) {
						if(event.results[i][0].transcript.substring(0, prevTxt.length) == prevTxt) this.model += event.results[i][0].transcript.substring(prevTxt.length)
						else this.model += event.results[i][0].transcript
						this.setFieldValue()
						prevTxt = event.results[i][0].transcript
						this.stopRecordingEvent.emit()
						break
					}
					else this.model += '<em class="interim">' + event.results[i][0].transcript + '</em>'
				}
			})
		},
		stopRecording() {
			this.recorder.stop()
			this.recording = false
			if(this.onBeforeSubmitSub) this.onBeforeSubmitSub.unsubscribe()
			return this.stopRecordingEvent
		},
		removeAudioFile(index) {
			this.audioFiles.splice(parseInt(index), 1)
			this.setFieldValue()
		},
		setFieldValue() {
			this.field.setValue({
				text: this.model,
				voice: this.audioFiles
			})
		},
		setModelValue() {
			if(this.field.value) {
				this.model = this.field.value.text
				this.audioFiles = this.field.value.voice
			}
			else {
				this.model = null
				this.audioFiles = []
			}
		},
		mobileResult(data) {
			let req = {}
			req['context("data"):script.run("BlFormFieldVoiceSpeech", "' + data + '")'] = {
				text: 'local.data'
			}
			this.currentRequest = true
			Api.post('api/structure/', req).then(resp => {
				if(resp.text) {
					if(!this.model) this.model = ''
					this.model += resp.text
					this.setFieldValue()
				}
				this.currentRequest = false
				this.stopRecordingEvent.emit()
			})
		}
	}
}
</script>

<style scoped lang="scss">
.bl-icon-button.microphone {
	font-size: 40px;
	position: absolute;
	right: 5px;
	z-index: 2;
	margin-top: 5px;
	padding: 6px 10px;
	color: var(--bl-legend);
	transition: all .2s;
}

.bl-icon-button.microphone:active:after {
	top: 0;
}

.bl-icon-button.microphone:hover:after {
	top: 0;
}

.bl-icon-button.microphone.recording {
	color: var(--bl-on-surface);
	outline: 1px solid var(--bl-border);
}

label {
	color: var(--bl-legend);
	font-size: 12px;
	padding-bottom: 4px;
	display: block;
}

.errored {
	label {
		color: var(--bl-error);
	}

	.inputContainer {
		box-shadow: 0 0 0 2px var(--bl-error);
	}
}

.inputContainer {
	cursor: text;
	box-shadow: 0 0 0 1px var(--bl-border);
	border-radius: var(--bl-border-radius);
	overflow: hidden;
	display: flex;
	min-height: 70px;

	:deep em.interim {
		color: var(--bl-legend);
	}

	:deep .contentEditableContainer {
		max-width: calc(100% - 80px);
	}
}

.focused {
	label {
		color: var(--bl-primary);
	}

	.inputContainer {
		box-shadow: 0 0 0 2px var(--bl-primary);
	}
}

.blFormFieldRichText {
	position: relative;
}

.recordingIndicator {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: var(--bl-surface);
	position: absolute;
	right: 10px;
	margin-top: 10px;
	pointer-events: none;
	transition: transform .1s;

	> div {
		overflow: hidden;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		position: relative;
		opacity: 1;

		> div {
			width: 100%;
			background-color: var(--bl-secondary);
			bottom: 0;
			position: absolute;
			transition: height .1s;
		}
	}
}

div.requestIndicator {
	animation: requestIndicator infinite 2s linear;
	background: conic-gradient(var(--bl-secondary) 40%, var(--bl-surface) 0);
	width: 60px;
	height: 60px;
	position: absolute;
	right: 5px;
	border-radius: 50%;
	margin-top: 5px;

	> div {
		width: calc(100% - 4px);
		height: calc(100% - 4px);
		background-color: var(--bl-surface);
		border-radius: 50%;
		margin: 2px;
	}
}

@keyframes requestIndicator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.recordingIndicator::before {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: var(--bl-secondary);
	transform: scale(2);
	content: ' ';
	opacity: 0;
	position: absolute;
	animation: recording 2.5s infinite;
}

@keyframes recording {
	0% {
		transform: scale(1);
		opacity: .6;
	}
	40% {
		transform: scale(2);
		opacity: 0;
	}
}

.audioFiles {
	display: flex;
	flex-wrap: wrap;
	padding-top: 5px;

	> div {
		display: flex;
		height: 22px;
		padding: 2px 8px 2px 5px;
		margin: 2px 5px 0 0;
		align-items: center;
		font-weight: 500;
		background-color: var(--bl-background);
		border-radius: var(--bl-border-radius);

		> icon {
			color: var(--bl-legend);
			font-size: 18px;
			margin-right: 6px;
		}

		button {
			display: none;
			margin-right: 4px;
			font-size: 16px;
			background-color: var(--bl-error);
			color: white;
			width: 20px;
			padding: 0;
		}
	}

	> div:hover button {
		display: block;
	}

	> div:hover > icon {
		display: none;
	}
}
</style>