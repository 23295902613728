<template>
	<div v-bl-input :class="{errored: field.isErrored() && field.getTouched()}">
		<label>{{ field.label }}</label>
		<div class="inputContainer"><input type="color" :tabindex="tabindex" v-model="model" @change="field.setValue(model)" @blur="field.setTouched()" ref="field" /></div>
		<div class="color" @click="focusInput()">
			<div class="preview" :style="{backgroundColor: field.value}"></div>
			<span>{{ field.value }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BlFormFieldColor',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value
		}
	},
	created() {
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	},
	methods: {
		focusInput() {
			this.$refs.field.click()
		}
	}
}
</script>

<style scoped lang="scss">
.inputContainer {
	opacity: 1;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

div.bl-input {
	height: 33px;
}

div.color {
	display: flex;
	cursor: pointer;
	align-items: center center;
	margin-top: 8px;

	.preview {
		border: 1px solid var(--bl-border);
		border-radius: 50%;
		width: 100%;
		height: 15px;
		width: 15px;
		margin: 0 5px;
	}

	span {
		line-height: 20px;
	}
}
</style>