<template>
	<div class="loadingContainer">
		<div class="topGradientContainer"></div>
		<div class="gradientContainer"></div>
		<img :src="image" class="imageBlurBackground" />
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block; shape-rendering: auto;" width="20%" height="20%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
			<g transform="translate(50 50) scale(0.7000000000000001) translate(-50 -50)">
				<g transform="rotate(359.541 50.0011 50.0011)">
					<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" calcMode="spline" dur="4s" values="0 50 50;90 50 50;180 50 50;270 50 50;360 50 50" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"></animateTransform>
					<g transform="scale(0.996425 0.996425)">
						<animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="1;1;0.5" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
						<g transform="translate(25 25)">
							<rect x="-25" y="-25" width="52" height="52" fill="#e15b64" style="background: red;">
							</rect>
						</g>
						<g transform="translate(25 75)">
							<rect x="-25" y="-25" width="52" height="50" fill="#e15b64" transform="scale(1 1)">
								<animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="0;1;1" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
							</rect>
						</g>
						<g transform="translate(75 25)">
							<rect x="-25" y="-25" width="50" height="52" fill="#e15b64" transform="scale(1 1)">
								<animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="0;1;1" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
							</rect>
						</g>
						<g transform="translate(75 75)">
							<rect x="-25" y="-25" width="50" height="50" fill="#e15b64" transform="scale(1 1)">
								<animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="0;1;1" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
							</rect>
						</g>
					</g>
				</g>
			</g>
		</svg>
	</div>
</template>

<script>
import { Variables } from 'InterfaceBundle'

export default {
	name: 'InterfaceLoading',
	data() {
		return {
			image: Variables.getIcon()
		}
	}
}
</script>

<style scoped lang="scss">
.imageBlurBackground {
	position: absolute;
	height: 100%;
	object-fit: cover;
	filter: blur(80px);
	max-width: 100%;
	opacity: .5;
}

.loadingContainer {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: var(--bl-primary);
	display: flex;
	align-items: center;
	justify-content: center;
}

.gradientContainer {
	position: absolute;
	height: 100%;
	width: 100%;
	background: linear-gradient(45deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.3) 100%);
}

.topGradientContainer {
	position: absolute;
	height: 150px;
	width: 100%;
	background: linear-gradient(180deg, var(--bl-primary) 0%, rgba(0,0,0,0) 100%);
	top: 0;
	z-index: 1;
}

svg rect {
	fill: var(--bl-surface);
}
</style>