<template>
	<slot />
</template>

<script>

export default {
	name: 'BlListField',
	props: ['name', 'filter', 'grid', 'label', 'disableClick', 'formatted', 'load', 'aggregation'],
	inject: ['blListAddField', 'blListRemoveField'],
	data() {
		return {
			field: {}
		}
	},
	created() {
		this.field[this.name] = {}
		if(this.filter) this.field[this.name].filter = this.filter
		if(this.grid) this.field[this.name].grid = this.grid
		if(typeof this.label !== undefined) this.field[this.name].label = this.label
		if(this.disableClick) this.field[this.name].disableClick = this.disableClick
		this.field[this.name].formatted = this.formatted === false ? false : true
		if(this.load === false) this.field[this.name].disableLoad = true
		if(this.aggregation) this.field[this.name].aggregation = this.aggregation
		if(this.blListAddField) this.blListAddField(this.field)
	},
	unmounted() {
		if(this.blListRemoveField) this.blListRemoveField(this.field)
	}
}
</script>

<style scoped lang="scss">
</style>