<template>
	<button class="bl-icon-button" type="button" @mousedown="onMouseDown($event)" tabindex="-1">drag_indicator</button>
	<BlFormField v-if="field" :name="field" />
</template>

<script>
export default {
	name: 'BlCollectionMove',
	props: ['field'],
	inject: ['blFormCollectionElement', 'blFormCollectionValue', 'blFormCollectionIndex', 'blStandaloneSwapCallback'],
	data() {
		return {
			dragEl: null,
			dragElContent: null,
			startPosition: null,
			lastSwapPosition: null,
			currentIndex: null,
			limits: null,
			elements: null
		}
	},
	created() {
		//Required to set position on newly added fields
		if(this.blFormCollectionValue?.root) this.blFormCollectionValue.initializeMove(this.field)
	},
	unmounted() {
		this.onMouseUp()
	},
	methods: {
		/**
		 * Handles mousedown event, clones hovered element
		 * @param  {object} event
		 */
		onMouseDown(event) {
			this.dragEl = document.createElement('TABLE')
			if(this.blStandaloneSwapCallback) this.dragEl.style.boxShadow = 'none'
			else this.dragEl.style.boxShadow = '1px 4px 12px 0 rgb(0 0 0 / 30%)'
			this.dragEl.classList.add('bl-formtable')
			this.dragEl.style.borderRadius = 0
			this.dragEl.style.zIndex = '100000'
			this.dragEl.style.position = 'absolute'
			let tbody = document.createElement('TBODY')
			document.body.classList.add('grabbing')
			this.dragEl.appendChild(tbody)
			let boundingClient = this.blFormCollectionElement().getBoundingClientRect()
			this.dragEl.style.top = (boundingClient.top - 5) + 'px'
			this.dragEl.style.left = (boundingClient.left - 5) + 'px'
			this.dragEl.style.width = this.blFormCollectionElement().offsetWidth + 'px'
			this.dragElContent = this.blFormCollectionElement().cloneNode(true)
			tbody.appendChild(this.dragElContent)
			document.body.appendChild(this.dragEl)
			this.blFormCollectionElement().style.opacity = 0
			this.$nextTick(() => {
				let newChildren = this.dragElContent.children
				let i = 0
				for(let child of this.blFormCollectionElement().children) {
					newChildren[i].style.width = (child.offsetWidth - 3) + 'px'
					i++
				}
			})
			document.addEventListener('mouseup', this.onMouseUp)
			document.addEventListener('mousemove', this.onMouseMove)
			this.startPosition = event.clientY - 5
			this.lastSwapPosition = event.clientY
			this.currentIndex = this.blFormCollectionIndex()
			let containerBox = this.blFormCollectionElement().parentNode.parentNode.getBoundingClientRect()
			this.limits = [containerBox.y, containerBox.y + containerBox.height]
			this.elements = Array.from(this.blFormCollectionElement().parentNode.children).map(c => {
				let containerBox = c.getBoundingClientRect()
				return [containerBox.y, containerBox.y + containerBox.height]
			})
		},
		/**
		 * Handles mouse move and setting to new position
		 * @param  {object} event
		 */
		onMouseMove(event) {
			if(event.clientY < this.limits[0] || event.clientY > this.limits[1]) return
			this.dragEl.style.marginTop = (event.clientY - this.startPosition) + 'px'
			for(let index in this.elements) {
				if(event.clientY > this.elements[index][0] && event.clientY < this.elements[index][1] && this.blFormCollectionValue.value[index]) {
					if(index != this.currentIndex) {
						this.swapIndexes(this.currentIndex, index)
						this.currentIndex = index
						this.blFormCollectionValue.dataChange()
					}
					break
				}
			}
		},
		/**
		 * Clears all events listeners and remove ghost elements
		 */
		onMouseUp() {
			if(this.blFormCollectionElement()) this.blFormCollectionElement().style.opacity = 1
			document.removeEventListener('mouseup', this.onMouseUp)
			document.removeEventListener('mousemove', this.onMouseMove)
			if(this.dragEl) {
				document.body.removeChild(this.dragEl)
				this.dragEl = null
				this.dragElContent = null
			}
			document.body.classList.remove('grabbing')
		},
		/**
		 * Swap indexes in form object to display change
		 * @param  {number} i1
		 * @param  {number} i2
		 */
		swapIndexes(i1, i2) {
			if(this.blStandaloneSwapCallback) this.blStandaloneSwapCallback(i1, i2)
			else {
				let val = this.blFormCollectionValue.value
				let tmp = val[i1]
				val[i1] = val[i2]
				val[i2] = tmp
				this.setPosition()
			}
		},
		/**
		 * Set position field on form
		 */
		setPosition() {
			let pos = 1
			for(let item of this.blFormCollectionValue.value) {
				item.getChild(this.field).setValue(pos, false)
				pos++
			}
		}
	}
}
</script>

<style scoped lang="scss">
button {
	padding: 4px;
	color: var(--bl-legend);
	cursor: grab;
	min-width: 32px;
	min-height: 36px;
}

button:active {
	cursor: grabbing;
}
</style>