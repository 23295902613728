<template>
	<slot v-bind="modelValueSlot"></slot>
</template>

<script>
import { Api, ModelChangeEventHelpers } from 'ModelBundle'
import { ViewServices, Router, EventEmitter } from 'InterfaceBundle'

export default {
	name: 'BlView',
	props: {
		model: {
			type: String
		},
		modelId: {
			type: Number
		},
		sourceFields: {
			default: []
		}
	},
	emits: ['modelValue', 'sourceValue'],
	data() {
		return {
			registeredFields: [{name: 'id'}],
			addFieldTimeout: null,
			data: {},
			rt: null,
			modelMeta: null,
			loaded: false,
			isLoadedTimeout: null,
			initialLoad: true,
			modelValueSlot: {},
			mounted: true,
			updated: new EventEmitter(),
			source: {}
		}
	},
	watch: {
		modelId: function() {
			this.reset()
		},
		model: function() {
			this.reset()
		}
	},
	provide() {
		return {
			blViewAddField: field => {
				if(this.registeredFields.map(f => f.name).includes(field.name)) return
				this.registeredFields.push(field)
				//Timeout to avoid multiple calls
				if(this.addFieldTimeout) clearTimeout(this.addFieldTimeout)
				this.addFieldTimeout = setTimeout(() => {
					this.updateFields()
					this.addFieldTimeout = null
				}, 10)
			},
			blViewGetName: () => '',
			blViewGetData: () => this.data,
			blViewGetField: name => this.data[name],
			blViewProps: () => {
				return {model: this.model, id: this.getId()}
			},
			blViewLoaded: () => this.loaded,
			blViewUpdaded: () => this.updated
		}
	},
	methods: {
		getId() {
			return this.modelId ? this.modelId : ViewServices.routeParams.id
		},
		updateFields(force = false) {
			if(this.isLoadedTimeout) {
				clearTimeout(this.isLoadedTimeout)
				this.isLoadedTimeout = null
			}
			this.loaded = false
			if(!this.model) return
			let req = {
				model: this.model,
				id: this.getId(),
				fields: this.registeredFields.filter(f => !this.data[f.name] || force).concat(this.sourceFields.map(f => {
					return {name: f}
				}))
			}
			Api.post('api/one/', req).then(resp => {
				this.modelMeta = resp.model
				if(this.initialLoad) {
					this.rt = ModelChangeEventHelpers.listen(this.modelMeta.name, this.getId())
					this.rt.subscribe(() => {
						this.updateFields(true)
						this.loadSourceFields()
					})
					this.loadSourceFields()
					this.initialLoad = false
				}
				for(let item in resp.data) this.data[item] = {
					value: resp.data[item],
					metadata: resp.metadata[item] ? resp.metadata[item] : resp.metadata[item.replaceAll(/\[\d+\]/g, '[*]')]
				}
				if(force) {
					for(let key of Object.keys(this.data)) {
						if(!resp.data[key]) delete this.data[key]
					}
				}
				this.modelValueSlot = this.parseData()
				this.$emit('modelValue', this.parseData())
				this.updated.emit()
				this.isLoadedTimeout = setTimeout(() => this.loaded = true, 100)
			}).catch(err => {
				if(this.mounted && err && err.status == 404) Router.notFound()
			})
		},
		parseData() {
			let ret = {}
			for(let field in this.data) ret[field] = this.data[field].value
			return ret
		},
		loadSourceFields() {
			if(this.sourceFields.length == 0 || !this.model) return
			this.source = {}
			let req = {
				model: this.model,
				id: this.getId(),
				fields: this.sourceFields.map(f => {
					return {name: f, formatted: false}
				})
			}
			Api.post('api/one/', req).then(resp => {
				this.source = resp.data
				for(let key of Object.keys(this.source)) {
					if(this.source[key]?._df) this.source[key] = new Date(this.source[key]._df)
				}
				this.$emit('sourceValue', this.source)
			}).catch(err => {
				if(this.mounted && err && err.status == 404) Router.notFound()
			})
		},
		reset() {
			this.initialLoad = true
			this.data = {}
			if(this.rt) ModelChangeEventHelpers.unsubscribe(this.rt)
			this.updateFields(true)
		}
	},
	created() {
		this.modelValueSlot[this.model] = {}
	},
	unmounted() {
		this.mounted = false
		if(this.rt) ModelChangeEventHelpers.unsubscribe(this.rt)
	}
}
</script>

<style scoped lang="scss">
</style>