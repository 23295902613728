<template>
	<div>
		<div style="white-space: normal;" v-html="text"></div>
		<div class="audioFiles" ref="audioFiles">
			<div v-for="key in Object.keys(getVoice())" :key="key" @click="toggle(key)">
				<icon>volume_up</icon>
				<span>{{ $t('Audio') }} {{ parseInt(key) + 1 }}</span>
				<audio @loadedmetadata="checkForDuration($event)">
					<source :src="voice[key]" type="audio/mpeg">
				</audio>
				<div class="timeContainer" :style="{background: 'conic-gradient(var(--bl-secondary) ' + (players[key]?.el.currentTime / players[key]?.el.duration) * 100 + '%, var(--bl-border) 0)'}">
					<button class="bl-icon-button">{{ players[key]?.playing ? 'pause' : 'play_arrow' }}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BlViewVoice',
	props: ['text', 'voice'],
	data() {
		return {
			players: [],
			forceRefresh: null
		}
	},
	mounted() {
		this.initializeAudioPlayers()
	},
	watch: {
		voice() {
			this.$nextTick(() => this.initializeAudioPlayers())
		}
	},
	methods: {
		checkForDuration(event) {
			const element = event.target
			if(element.duration === Infinity) {
				element.currentTime = 1e101
				setTimeout(() => {
					element.currentTime = 0
					this.$forceUpdate()
				}, 200)
			}
		},
		toggle(index, checkPlayer = true) {
			//Check if another player is playing, if it is stop it
			if(checkPlayer) {
				let i = 0
				for(const player of this.players) {
					if(player.playing && i != parseInt(index)) {
						this.toggle(i, false)
						break
					}
					i++
				}
			}

			const player = this.players[index]
			if(player.playing) {
				player.el.pause()
				clearTimeout(this.forceRefresh)
			}
			else {
				player.el.play()
				this.forceRefresh = setInterval(() => {
					this.$forceUpdate()
					if(player.el.ended) this.toggle(index, false)
				}, 50)//Force update media player position
			}
			player.playing = !player.playing
		},
		initializeAudioPlayers() {
			this.players = []
			const players = this.$refs.audioFiles?.children
			if(players) {
				for(const player of players) this.players.push({el: player.querySelector('audio'), playing: false})
			}
		},
		getVoice() {
			return this.voice ? this.voice : []
		}
	}
}
</script>

<style scoped lang="scss">
	.audioFiles {
		display: flex;
		flex-wrap: wrap;

		> div {
			user-select: none;
			cursor: pointer;
			display: flex;
			margin: 5px 5px 0 0;
			align-items: center;
			border: 1px solid var(--bl-border);
			border-radius: var(--bl-border-radius);
			padding: 2px 5px;

			> span {
				margin: 0 5px;
			}

			> icon {
				font-size: 20px;
			}
		}
	}

	.timeContainer {
		border-radius: 50%;
		width: 36px;
		height: 36px;
		transition: all .1s;

		> button {
			background-color: var(--bl-surface);
			margin: 4px 0 0 4px;
			width: 28px;
			padding: 0px;
		}
	}
</style>