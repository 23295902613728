import { ViewServices } from './ViewServices.js'
import { EventEmitter } from './EventEmitter.js'
import { ScriptLoader } from './ScriptLoader.js'

/**
 * Map helpers
 */
export const MapService = {
	_gmapLoaded: false,
	/**
	 * Decode polyline
	 */
	decodePolyline(polyline) {
		let index = 0, lat = 0, lng = 0, coordinates = [], shift = 0, result = 0, byte = null, latitudeChange, longitudeChange, factor = Math.pow(10, 6)

		while(index < polyline.length) {
			byte = null
			shift = 0
			result = 0

			do {
				byte = polyline.charCodeAt(index++) - 63
				result |= (byte & 0x1f) << shift
				shift += 5
			} while (byte >= 0x20)

			latitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1))
			shift = result = 0

			do {
				byte = polyline.charCodeAt(index++) - 63
				result |= (byte & 0x1f) << shift
				shift += 5
			} while (byte >= 0x20)

			longitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1))

			lat += latitudeChange
			lng += longitudeChange

			coordinates.push({lat : (lat / factor), lng: (lng / factor)})

		}
		
		return coordinates
	},
	/**
	 * Import google maps api
	 */
	load() {
		const ret = new EventEmitter()

		if(!this._gmapLoaded) {
			this._gmapLoaded = ret
			ScriptLoader.importJS('https://maps.googleapis.com/maps/api/js?v=weekly&libraries=places&key=' + ViewServices.interfaceData.gmapKey).then(() => {
				ret.emit()
				this._gmapLoaded = true
			})
		}
		else if(this._gmapLoaded === true) setTimeout(() => ret.emit())
		else return this._gmapLoaded

		return ret
	}
}

ViewServices.map = MapService