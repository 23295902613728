/**
 * Handles date formatting
 * @type {Object}
 */
export const DateFormat = {
	//Todo : handle translated values
	dayShort: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
	dayMedium: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
	dayLong: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
	monthLong: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
	monthShort: ['Ja', 'Fe', 'Ma', 'Av', 'Ma', 'Ju', 'Ji', 'Ao', 'Se', 'Oc', 'No', 'De'],
	//Set as a parameter depending on locale
	firstDayOfWeek: 0,
	php2jsTokens: {
		'dd': 'DD',
		'd': 'D',
		'MM': 'MM',
		'M': 'M',
		'yyyy': 'YYYY',
		'yy': 'YY',
		'HH': 'HH',
		'mm': 'mm'
	},

	/**
	 * Get month name
	 * @param  {Date}    date
	 * @param  {Boolean} long
	 * @return {string}
	 */
	getMonthName(date, long = true) {
		return long ? this.monthLong[date.getMonth()] : this.monthShort[date.getMonth()]
	},

	/**
	 * Get local day
	 * @param  {Date} date
	 * @return {integer}
	 */
	getLocalDay(date) {
		return (date.getDay() + 6 + this.firstDayOfWeek) % 7
	},

	/**
	 * Get day name
	 * @param  {Date}    date
	 * @param  {string}  format
	 * @return {string}
	 */
	getDayName(date, format = 'short') {
		let day = this.getLocalDay(date)
		let source = format == 'short' ? this.dayShort : (format == 'long' ? this.dayLong : this.dayMedium)
		return source[day]
	},

	/**
	 * Transform PHP formatting string to day.js formatting string
	 * @param {string} php format
	 * @return {string} dayjs format
	 */
	transformPHPFormatToJS(format) {
		for(let token in this.php2jsTokens) format = format.replace(token, this.php2jsTokens[token])
		return format
	},

	/**
	 * Create mask
	 * @param  {string} mask
	 * @return {string}
	 */
	createMask(mask) {
		for(let token in this.php2jsTokens) mask = mask.replace(token, this.php2jsTokens[token].split('').map(() => '#').join(''))
		return mask
	}
}
