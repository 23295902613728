<template>
	<div class="bl-card">
		<div style="display: flex; margin-bottom: 10px;">
			<div v-bl-input>
				<label>Request (JSON)</label>
				<input type="text" v-model="arguments" />
			</div>
			<BlButton style="margin-top: 3px; margin-left: 24px;" label="Refresh" @click="loadData()" :loading="currentRequest" />
		</div>
		<iframe v-if="base64" :src="'data:' + base64 + '#toolbar=0&navpanes=0'"></iframe>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'

export default {
	name: 'BlDocumentFormPreview',
	props: ['id', 'collectionId'],
	data() {
		return {
			base64: null,
			currentRequest: false,
			arguments: '{}'
		}
	},
	methods: {
		loadData() {
			this.base64 = null
			this.currentRequest = true
			let url = this.id ? ('document/preview/' + this.id) : ('document/collection-preview/' + this.collectionId)
			Api.get(url, JSON.parse(this.arguments)).then(resp => {
				this.currentRequest = false
				this.base64 = resp.data
			}, () => this.currentRequest = false)
		}
	}
}
</script>

<style scoped lang="scss">
	.bl-card {
		display: flex;
		flex-direction: column;
	}

	iframe {
		flex: 1;
		border: 0;
		width: 100%;
		background-color: #525659;
	}
</style>