import { Router } from 'InterfaceBundle'

export default {
	mounted(el, binding) {
		let url = binding.value
		let autoBind = false
		let autoHide = true
		let animate = null
		if(url && typeof url === 'object' && url.url) {
			if(url.autoBind) autoBind = true
			if(url.autoHide === false) autoHide = false
			if(url.animate) animate = url.animate
			url = url.url
		}
		if(url) {
			if(!Array.isArray(url)) url = [url]
			if(!Router.canAccess(url[0])) {
				if(autoHide) el.style.display = 'none'
			}
			else {
				let uri = Router.generateUri(url[0], url[1], autoBind)
				let isLink = el.tagName == 'A'
				el._clickEventListener = ev => {
					ev.preventDefault()
					if(ev.ctrlKey) window.open(uri)
					else Router.navigateUri(uri, animate)
					return false
				}
				el.addEventListener('click', el._clickEventListener)
				if(!isLink) {
					el._mouseupEventListener = ev => {
						if(ev.which > 2) return
						ev.preventDefault()
						if(ev.which == 2) window.open(uri)
						return false
					}
					el.addEventListener('mouseup', el._mouseupEventListener)
				}
				el.setAttribute('href', uri)
				el.style.cursor = 'pointer'
			}
		}
	},
	unmounted(el) {
		if(el._clickEventListener) el.removeEventListener('click', el._clickEventListener)
		if(el._mouseupEventListener) el.removeEventListener('mouseup', el._mouseupEventListener)
	}
}