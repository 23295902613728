<template>
	<div>
		<BlSelect :data="field.options.choices" :loading="!field.options.loadAll" :label="field.label" :errored="field.isErrored() && field.getTouched()" v-model="model" @change="field.setValue(model)" @focus="loadValues()" ref="field" :specialChoices="specialChoices" :disabled="field.options.disabled" :tabindex="tabindex" :preferredChoices="field.options.preferredChoices" />
	</div>
</template>

<script>
import { Dialog, Router } from 'InterfaceBundle'
import { FormEvents } from 'FormBundle'

export default {
	name: 'BlFormFieldModel',
	inject: ['blFormLiveUpdateFields', 'blForm'],
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value,
			loaded: false,
			specialChoices: null
		}
	},
	watch: {
		'field.options.autoSelectValue'() {
			if(this.field.options.autoSelectValue) {
				this.field.setValue(this.field.options.autoSelectValue)
				this.model = this.field.options.autoSelectValue
			}
		}
	},
	methods: {
		loadValues() {
			this.field.setTouched()
			if(!this.loaded) {
				this.loaded = true
				this.reloadValues().catch(() => {
					const options = this.field.options
					options.loadAll = false
					this.loaded = false
				})
			}
		},
		reloadValues() {
			let req = {}
			req[this.field.getQualifiedName()] = {loadAll: true}
			return this.blFormLiveUpdateFields(req)
		}
	},
	created() {
		if(this.field.options.newitem) this.specialChoices = [{key: '__newitem', label: this.$t('form.createItem'), value: search => {
			//Parse option
			let component = this.field.options.newitem.split(':')
			let field = null
			let propName = null
			if(component.length >= 2) field = component[1]
			if(component.length >= 3) propName = component[2]
			component = component[0]

			//Handle form submission
			let submitSub = FormEvents.submitted.once(response => {
				this.$nextTick(() => {
					this.reloadValues().then(() => {
						this.field.setValue(response.__id)
						this.model = this.field.value
					})
				})
				Dialog.close(false)
			})

			//Initialize value if required
			let readySub = (search && field) ? FormEvents.ready.once(form => form.form.getChild(field).setValue(search)) : null

			//Create dialog
			let matchingRoutes = Router.routes.filter(r => r.component == component)
			FormEvents.disableRouting = true
			let childProps = {}
			if(propName) childProps[propName] = this.field
			Dialog.custom({
				component: component,
				componentProps: childProps,
				title: matchingRoutes.length ? matchingRoutes[0].title : ''
			}).catch(() => {
				FormEvents.disableRouting = false
				if(readySub) readySub.unsubscribe()
				submitSub.unsubscribe()
			})
		}}]

		this.field.emitter.focus.subscribe(() => this.$refs.field.$refs.searchInput.focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	}
}
</script>

<style scoped lang="scss">
</style>