<template>
	<div style="display: flex; height: calc(100% - 5px);" @dragover.prevent="dragOver = true" @dragleave="dragOver = false" @drop.prevent.stop="dropHandler($event)">
		<div class="dragOver" v-if="dragOver">
			<i class="material-icons">file_download</i>
		</div>
		<div class="bl-card" style="display: flex; flex-direction: column; margin-bottom: -5px;" :style="{width: previewedFileWidth + 'px', flex: previewedFile ? 'none' : '1'}">
			<div class="navigation">
				<div v-for="(item, index) in navigation" :key="item.path">
					<span @click="openDirectory(item.path)">{{ item.name }}</span>
					<i class="material-icons" v-if="index != navigation.length - 1">navigate_next</i>
				</div>
				<div style="flex: 1;"></div>
				<BlButton label="Upload file" icon="download" classlist="dense outlined" @click="uploadFiles()" style="margin-right: 5px;" />
				<BlButton label="New folder" icon="create_new_folder" classlist="dense outlined" @click="createDirectory()" />
			</div>
			<div class="elements bl-light-scroll">
				<div v-for="item in items" :key="item.id">
					<div class="item" @click="open(item)">
						<div class="previewContainer" :style="{'background-image': getItemPreview(item)}">
							<i class="material-icons" v-if="item.type == 'Folder'">folder</i>
						</div>
						<div class="name">
							<div class="folderSmallIcon material-icons" v-if="item.type == 'Folder'">folder_open</div>
							<img v-if="item.icon" :src="'https://static.mixsuite.fr/file_icons/' + item.icon + '.svg'" />
							<svg v-if="item.uploading" width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="margin: 10px 5px 10px 5px;">
								<circle cx="50" cy="50" r="30" stroke="var(--bl-surface)" stroke-width="10" fill="none"></circle>
								<circle cx="50" cy="50" r="30" stroke="var(--bl-primary)" stroke-width="10" fill="none" transform="rotate(144 50 50)">
									<animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
									<animate attributeName="stroke-dasharray" calcMode="linear" values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882" keyTimes="0;0.5;1" dur="1" begin="0s" repeatCount="indefinite"></animate>
								</circle>
							</svg>
							<b>{{ item.srcName ? item.srcName : item.name }}</b>
							<icon class="publicPreview" v-if="item.publicId">public</icon>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="previewedFile" v-bl-resize="{value: previewedFileWidth, min: 214}" @resize="previewedFileWidth = $event" @currentresize="previewFileResizing = $event" style="height: calc(100% + 5px);"></div>
		<div class="bl-card previewFile" v-if="previewedFile" style="margin-bottom: -5px">
			<div class="topBar">
				<img :src="'https://static.mixsuite.fr/file_icons/' + previewedFile.icon + '.svg'" />
				<h4>
					<div>{{ previewedFile.srcName ? previewedFile.srcName : previewedFile.name }}</div>
					<h5 v-if="previewedFile.srcName">{{ previewedFile.name }}</h5>
				</h4>
				<BlButton label="Download" icon="download" @click="downloadFile()" />
				<button class="bl-icon-button" v-bl-tooltip="'Close'" @click="previewedFile = null">close</button>
			</div>
			<BlTabs style="margin: -15px -5px 0 -5px; flex: 1;">
				<BlTab>
					<BlTabHeader>
						<i class="material-icons">article</i>
						Preview
					</BlTabHeader>
					<BlTabBody>
						<div class="hidePopoutButton" v-if="iframeSrc && iframeSrc.includes('google')" />
						<div style="margin-top: -15px; margin-bottom: -2px;" class="bl-loader-line" :style="{opacity: iframeLoaded ? 0 : 1}"></div>
						<iframe :style="{pointerEvents: previewFileResizing ? 'none' : null}" :class="{microsoft: iframeSrc.includes('live.com')}" v-if="iframeSrc" ref="iframe" :src="iframeSrc" />
					</BlTabBody>
				</BlTab>
				<BlTab>
					<BlTabHeader>
						<i class="material-icons">info</i>
						Details
					</BlTabHeader>
					<BlTabBody>
						<div class="fileDetails" v-if="fileDetails">
							<h4>Who has access</h4>
							<ul class="accessDetails">
								<li>
									<BlProfilePicture :id="1" />
									<span>User 1</span>
								</li>
								<li>
									<BlProfilePicture :id="2" />
									<span>User 2</span>
								</li>
							</ul>
							<h4>System properties</h4>
							<table>
								<tr>
									<td>Extension :</td>
									<td>{{ fileDetails.extension }}</td>
								</tr>
								<tr>
									<td>Type :</td>
									<td>{{ fileDetails.fileType }}</td>
								</tr>
								<tr>
									<td>Size :</td>
									<td>{{ fileDetails.size }}</td>
								</tr>
								<tr>
									<td>Modified :</td>
									<td>{{ fileDetails.modified }}</td>
								</tr>
								<tr>
									<td>Created :</td>
									<td>{{ fileDetails.created }}</td>
								</tr>
							</table>
							<h4>Actions</h4>
							<div style="display: flex; justify-content: space-around;">
								<button class="bl-large-button" disabled>
									<i class="material-icons">share</i>
									Share
								</button>
								<button class="bl-large-button" @click="rename()">
									<i class="material-icons">edit</i>
									Rename
								</button>
								<button class="bl-large-button" disabled>
									<i class="material-icons">drive_file_move_rtl</i>
									Move to
								</button>
								<button class="bl-large-button" @click="deleteFile()">
									<i class="material-icons">delete</i>
									Delete
								</button>
							</div>
						</div>
					</BlTabBody>
				</BlTab>
				<BlTab>
					<BlTabHeader>
						<i class="material-icons">view_timeline</i>
						Activity
					</BlTabHeader>
					<BlTabBody>
						Activity
					</BlTabBody>
				</BlTab>
			</BlTabs>
		</div>
	</div>
</template>

<script>
import { UploadHelpers } from 'FileBundle'
import { Api } from 'ModelBundle'
import { Auth } from 'AuthBundle'
import { Dialog, Snackbar } from 'InterfaceBundle'

export default {
	name: 'BlFileExplorer',
	data() {
		return {
			items: [],
			navigation: [],
			previewedFile: null,
			dragOver: false,
			previewedFileWidth: null,
			iframeSrc: null,
			iframeLoaded: false,
			reloadTimeoutInstance: null,
			previewFileResizing: false,
			fileDetails: null,
			uploadTimeout: null
		}
	},
	created() {
		this.openDirectory()
	},
	methods: {
		getItemPreview(item) {
			if(item.type != 'File') return null
			if(item.preview) return 'url(\'data:image/jpeg;base64,' + item.preview + '\')'
			return 'url(\'https://static.mixsuite.fr/blocks/file_preview_fallback.png\')'
		},
		open(item) {
			if(item.uploading) return
			else if(item.type == 'Folder') this.openDirectory(item.path ? item.path + '/' + item.name : item.name)
			else {
				Api.get('file-details/' + item.uuid).then(resp => {
					this.fileDetails = resp
					this.iframeSrc = null
					this.$nextTick(() => {
						let fullPath = window.location.origin + '/api/file/' + item.uuid + '?bearer=' + Auth.getToken()
						this.iframeSrc = this.fileDetails.embededViewerUrl.replace('{{url}}', encodeURIComponent(fullPath)).replace('{{token}}', Auth.getToken())
						this.iframeLoaded = false
						this.$nextTick(() => {
							this.$refs.iframe.onload = () => this.iframeLoaded = true
							this.reloadTimeout()
						})
					})
				})
				if(!this.previewedFileWidth) this.previewedFileWidth = this.$el.offsetWidth * 0.6
				this.previewedFile = item
			}
		},
		downloadFile() {
			Api.openWindow('file/' + this.previewedFile.uuid)
		},
		openDirectory(directory = null) {
			let request = {
				fields: [
					{name: 'id'},
					{name: 'publicId'},
					{name: 'uuid'},
					{name: 'name'},
					{name: 'srcName'},
					{name: 'path'},
					{name: 'type'},
					{name: 'preview'},
					{name: 'icon'}
				],
				filters: directory ? ['path', '=', directory] : ['path', 'NULL'],
				limit: 100,
				model: 'internals.filesystem'
			}
			Api.post('api/', {data: request}).then(resp => {
				this.items = resp.data.data

				//Set navigation
				this.navigation = [{
					name: 'Root',
					path: null
				}]
				let parts = directory ? directory.split('/') : []
				for(let partIndex in parts) {
					let path = parts.slice(0, partIndex)
					path.push(parts[partIndex])
					this.navigation.push({
						name: parts[partIndex],
						path: path.join('/')
					})
				}
			})
		},
		reloadTimeout() {
			this.reloadTimeoutInstance = setTimeout(() => {
				if(this.$refs.iframe && this.$refs.iframe.contentWindow[0]) {
					this.iframeLoaded = true
					clearTimeout(this.reloadTimeoutInstance)
					this.reloadTimeoutInstance = null
				}
				else {
					this.showIframe = false
					setTimeout(() => {
						this.showIframe = true
						this.reloadTimeout()
					}, 10)
				}
			}, 1000)
		},
		rename() {
			Dialog.prompt({
				accept: 'Rename',
				cancel: 'Cancel',
				required: true,
				promptLabel: 'File name',
				promptValue: this.previewedFile.name.split('.')[0]
			}).then(fileName => {
				fileName += '.' + this.previewedFile.name.split('.')[1]
				Api.put('/file-rename/' + this.previewedFile.uuid, {name: fileName}).then(details => {
					this.fileDetails = details
					this.previewedFile.name = details.name
				})
			})
		},
		getCurrentPath() {
			return this.navigation[this.navigation.length - 1].path
		},
		deleteFile() {
			Dialog.confirm({
				title: 'Delete file "' + this.previewedFile.name + '" ?',
				accept: 'Delete',
				cancel: 'Cancel'
			}).then(() => {
				Api.delete('/file-delete/' + this.previewedFile.uuid).then(resp => {
					if(resp.success) {
						this.previewedFile = null
						Snackbar.open({text: 'File deleted'})
						this.openDirectory(this.getCurrentPath())
					}
					else Snackbar.open({text: 'Unable to delete file', error: true})
				})
			})
		},
		createDirectory() {
			Dialog.prompt({
				title: 'Create folder',
				accept: 'Create',
				cancel: 'Cancel',
				required: true,
				promptLabel: 'Folder name'
			}).then(dirName => {
				Api.post('/create-directory/', {name: dirName, path: this.getCurrentPath()}).then(resp => {
					if(resp.success) {
						Snackbar.open({text: 'Folder created'})
						this.openDirectory(this.getCurrentPath())
					}
					else Snackbar.open({text: 'Unable to create folder', error: true})
				})
			})
		},
		uploadFiles() {
			if(this.uploadTimeout) return
			this.uploadTimeout = true
			setTimeout(() => this.uploadTimeout = false, 500)
			UploadHelpers.upload(true, null, (success, fileOption) => {
				if(success) {
					let item = JSON.parse(JSON.stringify(fileOption))
					item.uploading = true
					item.type = 'File'
					this.items.push(item)
				}
			}, () => this.$forceUpdate(), this.finishUpload)
		},
		dropHandler(event) {
			this.dragOver = false
			UploadHelpers.dropHandler(event, true, fileOption => {
				let item = JSON.parse(JSON.stringify(fileOption))
				item.uploading = true
				item.type = 'File'
				this.items.push(item)
			}, () => this.$forceUpdate(), this.finishUpload)
		},
		finishUpload(fileOption) {
			if(fileOption.error) this.items = this.items.filter(i => i.uploading != true)
			else {
				Api.post('/upload/', {
					location: this.getCurrentPath(),
					name: fileOption.name,
					lastModified: fileOption.lastModified,
					tmpPath: fileOption.tmpName
				}).then(() => {
					this.openDirectory(this.getCurrentPath())
				})
			}
		}
	}
}
</script>

<style scoped lang="scss">
.previewFile {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;

	.topBar {
		z-index: 1;
		display: flex;
		align-items: center;

		img {
			margin: 20px;
			width: 18px;
			height: 18px;
		}

		h4 {
			flex: 1;
			font-size: 18px;
			font-family: Product sans;
			margin: 0;
			padding: 0;

			h5 {
				color: var(--bl-legend);
				font-weight: normal;
				font-size: 12px;
				font-family: 'Roboto';
				margin: 0;
				padding: 0;
			}
		}

		button {
			margin-right: 10px;
		}
	}

	iframe {
		background-color: #D1D1D1;
		flex: 1;
		border: none;
		width: 100%;
		height: 100%;
	}

	iframe.microsoft {
		margin: -1px;
	}

	.hidePopoutButton {
		position: absolute;
		width: 50px;
		height: 40px;
		background-color: #D1D1D1;
		right: 12px;
		top: 114px;
		z-index: 2;
	}
}

.navigation {
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--bl-border);
	margin: 0 -5px 5px -5px;
	padding: 5px 10px 10px 10px;

	div {
		display: flex;
		align-items: center;

		i.material-icons {
			margin-top: -2px;
		}

		span {
			cursor: pointer;
			border-radius: var(--bl-border-radius);
			padding: 7px 12px;
			transition: background-color .2s;
		}

		span:hover {
			background-color: var(--bl-background);
		}
	}
}

.elements {
	display: flex;
	flex-wrap: wrap;
	overflow-y: scroll;

	.item {
		user-select: none;
		margin: 5px;
		border: 1px solid var(--bl-border);
		border-radius: var(--bl-border-radius);
		width: 200px;
		height: 140px;
		overflow: hidden;
		cursor: pointer;

		.previewContainer {
			text-align: center;
			height: 100px;
			background-position: start;
			background-size: cover;
			background-repeat: no-repeat;

			i.material-icons {
				color: var(--bl-border);
				font-size: 100px;
			}
		}

		.name {
			color: var(--bl-legend);
			background-color: var(--bl-surface);
			display: flex;
			align-items: flex-start;
			max-width: 100%;
			padding-right: 5px;

			img {
				margin: 11px;
				width: 18px;
				height: 18px;
			}

			b {
				font-weight: normal;
				padding-top: 8px;
				white-space: nowrap;
				overflow: hidden;
				flex: 1;
				line-height: 26px;
				text-overflow: ellipsis;
				display: block;
			}

			.folderSmallIcon {
				margin: 11px;
				width: 18px;
				height: 18px;
				font-size: 14px;
				text-align: center;
				line-height: 18px;
				background-color: #F3B137;
				color: white;
				border-radius: 2px;
			}

			icon.publicPreview {
				color: var(--bl-legend);
				font-size: 20px;
				margin-top: 10px;
			}
		}
	}
}

.fileDetails {
	padding: 10px;

	h4 {
		margin: 10px 0;
		font-family: Product sans;
		font-size: 16px;
	}

	table tr td {
		padding: 5px;
	}

	table tr td:first-child {
		color: var(--bl-legend);
		padding-right: 20px;
	}

	.accessDetails {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: flex;
			align-items: center;
			padding: 2px 5px;

			span {
				margin-left: 5px;
			}
		}
	}
}

.dragOver {
	width: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	z-index: 4;
	pointer-events: none;

	i.material-icons {
		color: var(--bl-on-primary);
		animation: dragOverAnimate 1s infinite;
		font-size: 80px;
		padding: 50px;
		border-radius: 50%;
		background-color: var(--bl-primary);
		margin: 0 10px;
	}
}

@keyframes dragOverAnimate {
	0% {
		box-shadow: 0 0 0 1px var(--bl-primary);
	}

	30% {
		box-shadow: 0 0 0 5px var(--bl-primary);
	}

	70% {
		box-shadow: 0 0 0 5px var(--bl-primary);
	}

	100% {
		box-shadow: 0 0 0 1px var(--bl-primary);
	}
}
</style>