import { EventEmitter } from './EventEmitter.js'

export const ViewCache = {
	_db: null,

	getDb() {
		const ret = new EventEmitter()
		if(!this._db) {
			const req = indexedDB.open('cache')
			req.onsuccess = e => {
				this._db = e.target.result
				ret.emit(this._db)
			}
			req.onupgradeneeded = e => {
				e.target.result.createObjectStore('cache', {keyPath: 'key'})
			}
		}
		else setTimeout(() => ret.emit(this._db))
		return ret
	},

	/**
	 * Store data
	 * @param  {string} key
	 * @param  {mixed}  data
	 */
	set(key, data) {
		const ret = new EventEmitter()
		this.getDb().once(db => {
			const transaction = db.transaction(['cache'], 'readwrite')
			transaction.objectStore('cache').add({key: key, data: JSON.stringify(data)})
			transaction.oncomplete = () => ret.emit()
		})
		return ret
	},

	/**
	 * Get data by key
	 * @param  {string} key
	 */
	get(key) {
		const ret = new EventEmitter()
		this.getDb().once(db => {
			const request = db.transaction(['cache']).objectStore('cache').get(key)
			request.onsuccess = () => ret.emit(request.result ? JSON.parse(request.result.data) : null)
		})
		return ret
	},

	/**
	 * Get data by key
	 * @param  {string} key
	 */
	remove(key) {
		const ret = new EventEmitter()
		this.getDb().once(db => {
			const transaction = db.transaction(['cache'], 'readwrite')
			transaction.oncomplete = () => ret.emit()
			transaction.objectStore('cache').delete(key)
		})
		return ret
	}
}