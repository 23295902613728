<template>
	<button class="bl-icon-button" @click="close()" style="float: right;">close</button>
	<BlTabs>
		<BlTab>
			<BlTabHeader>
				<i class="material-icons">task_alt</i>
				Validators
			</BlTabHeader>
			<BlTabBody>
				<div style="display: flex; margin-top: -15px;">
					<ul class="bl-light-scroll">
						<li v-for="validator in validators" :key="validator" @click="addValidator(validator)">
							<i class="material-icons">{{ validator.icon }}</i>
							<span>
								<b>{{ validator.name }}</b>
								<p>{{ validator.description }}</p>
							</span>
						</li>
					</ul>
					<div style="min-width: 500px;">
						<div class="validator" v-for="item in value" :key="item">
							<button class="bl-icon-button" @click="removeValidator(item)">delete</button>
							<h4>{{ item[0] }}</h4>
							<div v-bl-input v-for="(option, index) in getOptions(item[0])" :key="index">
								<label>{{ option }}</label>
								<input type="text" v-model="item[index + 1]" @change="setValue()" />
							</div>
						</div>
						<div v-if="!value.length" class="noData">
							<img src="https://static.mixsuite.fr/salessuite/no_data.png" />
							<div>{{ $t('No validators') }}</div>
						</div>
					</div>
				</div>
			</BlTabBody>
		</BlTab>
		<BlTab>
			<BlTabHeader>
				<i class="material-icons">code</i>
				JSON
			</BlTabHeader>
			<BlTabBody>
				<div style="min-width: 781px;">
					<BlFormFieldRichtext v-if="showField" :field="jsonField" />
				</div>
			</BlTabBody>
		</BlTab>
	</BlTabs>
	<div class="actionsContainer">
		<BlButton label="Save" @click="save()" />
	</div>
</template>

<script>
import { Api } from 'ModelBundle'
import { Dialog, EventEmitter } from 'InterfaceBundle'

export default {
	name: 'BlFormFieldValidationselectorDialog',
	props: ['model'],
	data() {
		return {
			validators: [],
			value: null,
			options: {},
			jsonFieldErrored: false,
			/**
			 * Json field options
			 * @type {Object}
			 */
			jsonField: {
				value: '',
				isErrored: () => this.jsonFieldErrored,
				getTouched: () => true,
				setTouched: () => false,
				setValue: v => {
					this.jsonFieldErrored = false
					try {
						this.value = JSON.parse(v)
					} catch(e) {
						this.jsonFieldErrored = true
						this.value = []
					}
				},
				label: 'JSON',
				emitter: {
					change: new EventEmitter(),
					focus: new EventEmitter(),
					beforeSubmit: new EventEmitter(),
					beforeValidation: new EventEmitter(),
					dataChange: new EventEmitter()
				},
				options: {
					code: {
						mode: 'json',
						height: 20,
						native: {showLineNumbers: false, showGutter: false, showPrintMargin: false}
					}
				}
			},
			showField: false
		}
	},
	created() {
		this.value = this.model ? JSON.parse(JSON.stringify(this.model)) : []
		this.setValue()
		let req = {}
		req['context("validators"):script.run("BlFormFieldValidationselectorDialog")'] = {
			'loop("validator"):local.validators': {
				name: 'local.validator.getName()',
				options: 'local.validator.getOptions()',
				icon: 'local.validator.getSelectorIcon()',
				description: 'local.validator.getSelectorDescription()'
			}
		}

		Api.post('api/structure/', req).then(resp => {
			this.validators = resp
		})

		document.addEventListener('keydown', this.handleKeyboardShortcuts)
	},
	unmounted() {
		document.removeEventListener('keydown', this.handleKeyboardShortcuts)
	},
	methods: {
		save() {
			Dialog.close(true, true, this.value)
		},
		close() {
			Dialog.close(false)
		},
		addValidator(validator) {
			let val = [validator.name]
			for(let i = 0; i < this.getOptions(validator.name).length; i++) val.push(null)
			this.value.push(val)
			this.setValue()
		},
		removeValidator(validator) {
			this.value = this.value.filter(v => v != validator)
			this.setValue()
		},
		getOptions(name) {
			let validator = this.validators.filter(v => v.name == name)
			if(validator.length) return Object.keys(validator[0].options)
			return []
		},
		setValue() {
			this.showField = false
			this.jsonField.value = JSON.stringify(this.value, null, '\t')
			this.$nextTick(() => this.showField = true)
		},
		handleKeyboardShortcuts(event) {
			if(event.key == 's' && event.ctrlKey && !this.currentRequest) {
				event.preventDefault()
				event.stopPropagation()
				this.save()
				return false
			}
		}
	}
}
</script>

<style scoped lang="scss">
	ul {
		list-style-type: none;
		margin: 0;
		padding: 10px 0 0 0;
		border-right: 1px solid var(--bl-border);
		width: 250px;
		overflow-x: auto;

		li {
			display: flex;
			margin: 0;
			padding: 10px 6px;
			border-radius: var(--bl-border-radius);
			margin-right: 6px;
			align-items: center;
			cursor: pointer;
			transition: background-color .2s;

			i {
				margin-right: 10px;
			}

			b {
				font-weight: 500;
				text-transform: capitalize;
			}

			p {
				color: var(--bl-legend);
				margin: 0;
				padding: 0;
				font-size: 12px;
			}
		}

		li:hover {
			background-color: var(--bl-background);
		}
	}

	.validator {
		width: 500px;
		border-top: 1px solid var(--bl-border);
		clear: both;
		padding: 15px;

		h4 {
			font-family: Product sans;
			margin: 0;
			padding: 0;
			text-transform: capitalize;
			font-size: 16px;
		}

		.bl-icon-button {
			float: right;
			transform: scale(.8);
			margin-top: -12px
		}

		.bl-input {
			margin-top: 10px;

			label {
				text-transform: capitalize;
			}
		}
	}

	.noData {
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			max-width: 150px;
			margin-bottom: 15px;
		}
	}

	.actionsContainer {
		margin-top: 10px;
		display: flex;
		justify-content: flex-end;
	}
</style>