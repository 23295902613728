<template>
	<div style="display: flex; max-height: 100%; min-height: 100%;">
		<div style="width: 350px; overflow: auto; border-right: 1px solid var(--bl-border); padding-right: 5px;" class="bl-light-scroll">
			<h1 style="margin-bottom: 10px;">{{ $t('exporter.title{label}', {label: label}) }}</h1>
			<BlModelExporterTree style="margin: 0; border: 0; padding: 0;" :items="tree" :methods="{load: load, update: updateTree}" />
		</div>
		<div class="preview bl-light-scroll">
			<div>
				<div v-if="requestMode">
					<BlStandaloneField type="richtext" :options="{code: {mode:'json','height':40}}" :label="$t('exporter.request')" v-model="requestJson" />
				</div>
				<table v-if="!requestMode && getPreview()?.data?.length" class="bl-datatable">
					<thead>
						<tr v-if="presetPreview?.data" class="presetPreviewTitle">
							<th :colspan="getPreviewFields().length">
								<div>
									<span>{{ presetPreview.preset.name }}</span>
								</div>
							</th>
						</tr>
						<tr>
							<th v-for="item in getPreviewFields()" :key="item.name">{{ $t(item.label) }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="line in getPreview().data" :key="line">
							<td v-for="item in getPreviewFields()" :key="item.name">
								<component v-if="line[item.name]?._cn" :is="line[item.name]._cn" v-bind="line[item.name]._cp"></component>
								<div v-else v-html="line[item.name]"></div>
							</td>
						</tr>
					</tbody>
				</table>
				<div v-if="!requestMode && getPreview()?.data?.length" class="gradient"></div>
			</div>
		</div>
		<div style="width: 300px; display: flex; flex-direction: column; border-left: 1px solid var(--bl-border); overflow: auto;" class="bl-light-scroll">
			<div>
				<div style="display: flex;">
					<h2 style="flex: 1;">
						<icon>page_info</icon>
						{{ $t('exporter.presets') }}
					</h2>
					<button class="bl-icon-button" @click="close()">close</button>
				</div>
				<div>
					<ul class="formatList">
						<li v-for="preset in presets" :key="preset.id" @click="selectPreset(preset)" :class="{selected: preset == editMode}" @mouseenter="previewPreset(preset)" @mouseleave="unpreviewPreset()">
							<img :src="'https://static.mixsuite.fr/file_icons/' + preset.icon + '.svg'" />
							<span style="font-weight: 500; margin-left: 2px; padding: 4px 0 3px 0;">{{ preset.name }}</span>
							<button v-if="(!editMode || editMode != preset) && preset.isOwner" class="bl-icon-button" v-bl-tooltip="$t('exporter.edit')" @click.stop="editPreset(preset)" style="margin-right: 5px;">edit</button>
							<button v-if="(!editMode || editMode != preset) && preset.isOwner" class="bl-icon-button" v-bl-tooltip="$t('exporter.delete')" @click.stop="deletePreset(preset)">delete</button>
							<button v-if="editMode == preset" class="bl-icon-button alwaysShow" v-bl-tooltip="$t('exporter.leaveEdit')" @click.stop="closeEdit()">cancel</button>
						</li>
					</ul>
				</div>
			</div>
			<div style="border-top: 1px solid var(--bl-border); margin-top: 10px;">
				<div>
					<h2 style="flex: 1;">
						<icon>text_snippet</icon>
						{{ $t('exporter.format') }}
					</h2>
					<ul class="formatList">
						<li v-for="item in formats" :key="item.value" :class="{selected: format == item.value}" @click="format = item.value" :style="{display: item.displayDefault || format == item.value || showMoreFormats ? null : 'none'}">
							<img :src="'https://static.mixsuite.fr/file_icons/' + item.icon + '.svg'" />
							<span>{{ $t(item.label) }}</span>
						</li>
					</ul>
					<a v-if="!showMoreFormats" @click="showMoreFormats = true" style="margin: 4px 0 0 15px; display: block; font-size: 12px;">{{ $t('exporter.formatShowMore') }}</a>
				</div>
			</div>
			<div style="border-top: 1px solid var(--bl-border); margin-top: 10px;" :style="{display: requestMode ? 'none' : null}">
				<div class="orderColumn">
					<h2>
						<icon>sort</icon>
						{{ $t('exporter.sortColumns') }}
					</h2>
					<div v-if="selectedFields.length">
						<BlStandaloneSortable v-model="selectedFields">
							<template #default="{ item }">
								<div style="display: flex; align-items: center;">
									<BlCollectionMove />
									{{ $t(item.label) }}
								</div>
							</template>
						</BlStandaloneSortable>
					</div>
				</div>
			</div>
			<div style="border-top: 1px solid var(--bl-border); margin-top: 10px;">
				<div class="orderColumn">
					<h2>
						<icon>data_object</icon>
						<span style="flex: 1; cursor: pointer;" @click="requestMode = !requestMode">{{ $t('exporter.advanced') }}</span>
						<input style="margin-top: -1px; margin-right: 10px;" type="checkbox" class="bl-checkbox" v-model="requestMode" />
					</h2>
				</div>
			</div>
			<div style="flex: 1;"></div>
			<div style="text-align: right; display: flex; align-items: center; padding-left: 5px;">
				<BlButton :label="$t(editMode ? 'exporter.edit' : 'exporter.savemodel')" :icon="editMode ? 'edit' : 'page_info'" @click="saveModel()" class="outlined dense" />
				<div style="flex: 1"></div>
				<BlButton :label="$t('exporter.export')" icon="import_export" @click="exportData()" />
			</div>
		</div>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'
import { Snackbar, Dialog, EventEmitter } from 'InterfaceBundle'

export default {
	name: 'BlModelExporter',
	props: ['model', 'defaultFields', 'defaultFilters'],
	data() {
		return {
			tree: null,
			label: null,
			preview: null,
			showMoreFormats: false,
			selectedFields: [],
			format: 'xlsx',
			presets: [],
			formats: [
				{label: 'Excel', value: 'xlsx', icon: 'excel_color', displayDefault: true},
				{label: 'Pdf', value: 'pdf', icon: 'pdf_color', displayDefault: true},
				{label: 'Print', value: 'print', icon: 'print_color', displayDefault: true},
				{label: 'Csv', value: 'csv', icon: 'tables_bw', displayDefault: false},
				{label: 'Json', value: 'json', icon: 'html_color', displayDefault: false}
			],
			requestMode: false,
			requestJson: '',
			editMode: null,
			presetPreview: null
		}
	},
	created() {
		//Avoid preview when user enters the component
		this.disablePreview = true
		setTimeout(() => this.disablePreview = false, 500)
		this.loadPresets()
		this.load(null).once(() => this.selectFields(this.defaultFields).once(() => this.updatePreview()))
	},
	methods: {
		close() {
			Dialog.close()
		},
		loadPresets() {
			const req = {}
			req['context("presets"):script.run("InternalsImportGetExporterPresets", "' + this.model + '")'] = {
				presets: {
					'loop("preset"):local.presets': {
						id: null,
						name: null,
						request: null,
						format: null,
						requestMode: null,
						isOwner: 'local.preset.owner.id == auth.getUser().id',
						permissions: null
					}
				}
			}

			Api.post('api/structure/', req).then(resp => {
				this.presets = resp.presets.map(p => {
					p.icon = this.formats.filter(f => f.value == p.format)[0].icon
					return p
				})
			})
		},
		load(into = null) {
			const ret = new EventEmitter()
			if(into && into.nodes) {
				delete into.nodes
				this.updateTree()
				this.updatePreview()
				return
			}
			const req = {}
			req['context("data"):script.run("InternalsImportGetExporterMetadata", "' + (into ? into.loadChildren : this.model) + '")'] = {
				data: 'local.data'
			}

			if(into) into.nodes = []
			Api.post('api/structure/', req).then(resp => {
				if(into) into.nodes = resp.data.properties
				else {
					this.tree = {nodes: resp.data.properties}
					this.label = resp.data.label
				}

				ret.emit()
			})

			return ret
		},
		updateTree() {
			this.selectedFields = this.getSelectedFields(this.tree)
			this.updatePreview()
		},
		selectFields(fields) {
			let ret = new EventEmitter()

			//Unselected previously selected fields
			const unselect = fields => {
				for(let field of fields) {
					if(field.selected) field.selected = false
					if(field.nodes) unselect(field.nodes)
				}
			}
			unselect(this.tree.nodes)

			//Do select
			let loadedFields = new EventEmitter()
			this._selectFields(fields, loadedFields, this.tree, fields)
			loadedFields.once(() => {
				const selected = this.getSelectedFields(this.tree)
				this.selectedFields = []
				this.$nextTick(() => {
					for(let field of fields) this.selectedFields.push(selected.filter(f => f.name == field)[0])
					ret.emit()
				})
			})

			return ret
		},
		_selectFields(fields, ret, item, allFields) {
			for(let node of item.nodes) {
				if(fields.includes(node.name)) node.selected = true
			}
			//Subchildren
			let loadByParent = {}
			for(let node of fields) {
				let parts = node.split('.')
				if(parts.length > 1) {
					if(!loadByParent[parts[0]]) loadByParent[parts[0]] = {fields: [], into: item.nodes.filter(n => n.name == parts[0])[0]}
					loadByParent[parts[0]].fields.push(parts.slice(1).join('.'))
				}
			}
			for(let parentLoad of Object.values(loadByParent)) {
				if(parentLoad.into.nodes) this._selectFields(parentLoad.fields, ret, parentLoad.into, allFields)
				else this.load(parentLoad.into).once(() => this._selectFields(parentLoad.fields, ret, parentLoad.into, allFields))
			}
			if(this.getSelectedFields(this.tree).length == allFields.length) this.$nextTick(() => ret.emit())
		},
		updatePreview() {
			let req = {
				data: {
					model: this.model,
					fields: this.selectedFields.map(f => {
						return {name: f.name}
					}),
					filters: this.defaultFilters ? this.defaultFilters : null,
					limit: 15
				}
			}
			this.setRequestJson()
			Api.post('api/', req).then(resp => this.preview = resp.data)
		},
		getSelectedFields(tree, parents = []) {
			let ret = []
			for(let prop of tree.nodes) {
				if(prop.nodes) ret = ret.concat(this.getSelectedFields(prop, parents.concat(prop.name)))
				if(prop.selected) ret.push({name: parents.concat(prop.name).join('.'), label: prop.label})
			}
			return ret
		},
		setRequestJson() {
			if(!this.requestMode) this.requestJson = JSON.stringify(this.getRequest())
		},
		exportData() {
			this.doExport({
				data: this.requestMode ? JSON.parse(this.requestJson) : this.getRequest(),
				format: this.format
			})
		},
		getRequest() {
			return {
				model: this.model,
				fields: this.selectedFields.map(f => {
					return {name: f.name}
				}),
				filters: this.defaultFilters ? this.defaultFilters : [],
				limit: -1
			}
		},
		doExport(req) {
			const format = this.formats.filter(f => f.value == req.format)[0]

			const snackProps = format.value == 'print' ? {
				title: this.$t('exporter.preparingDocument')
			} : {
				name: this.preview.model.label + '.' + format.value,
				icon: format.icon,
				title: this.$t('exporter.downloading')
			}
			if(req.format == 'print') req.format = 'pdf'

			const snackbar = Snackbar.open({
				clickToClose: false,
				autoClose: false,
				component: 'InterfaceFileSnackbar',
				componentProps: snackProps
			})

			Api.request('POST', 'export/', req, {}, true, true).then(resp => {
				const fileName = resp.headers.get('Content-Disposition').split('filename=')[1].replaceAll('"', '')
				resp.blob().then(blob => {
					if(format.value == 'print') {
						const embed = document.createElement('iframe')
						embed.setAttribute('src', window.URL.createObjectURL(blob))
						document.body.appendChild(embed)
						embed.contentWindow.print()
						snackbar.hide()
					}
					else {
						const url = window.URL.createObjectURL(blob)
						const a = document.createElement('a')
						a.style.display = 'none'
						a.href = url
						a.download = fileName
						document.body.appendChild(a)
						a.click()
						window.URL.revokeObjectURL(url)
						snackbar.hide()
					}
				})
			})
		},
		selectPreset(preset) {
			this.doExport({
				data: preset.request,
				format: preset.format
			})
		},
		saveModel() {
			Dialog.custom({
				component: 'BlModelExporterSaveModel',
				componentProps: {edit: this.editMode},
				title: this.$t('exporter.savemodel')
			}).then(resp => {
				const request = {
					name: resp.name,
					model: this.model,
					permissions: resp.permissions,
					request: this.requestMode ? this.requestJson : JSON.stringify(this.getRequest()),
					format: this.format,
					requestMode: this.requestMode
				}
				const promise = this.editMode ? Api.put('form/internals.exportpreset/' + this.editMode.id, {submit: request}) : Api.post('form/internals.exportpreset', {submit: request})
				promise.then(() => {
					Snackbar.open({
						text: this.$t('exporter.saved')
					})
					this.loadPresets()
					this.closeEdit()
				}).catch(() => {
					Dialog.alert({
						title: 'Error'
					})
				})
			})
		},
		deletePreset(preset) {
			Dialog.confirm({
				title: this.$t('exporter.deleteTitle'),
				accept: this.$t('exporter.delete')
			}).then(() => {
				const req = {}
				req['context("data"):script.run("internalsExportpresetArchive", ' + preset.id + ')'] = {
					response: 'local.data'
				}
				Api.post('api/structure/', req).then(resp => {
					if(resp.response) {
						Snackbar.open({
							text: this.$t('exporter.deleted')
						})
						this.loadPresets()
					}
					else Dialog.alert({
						title: 'Error'
					})
				})
			})
		},
		editPreset(preset) {
			this.editMode = preset
			this.format = preset.format
			if(preset.requestMode) {
				//Timeout to force refresh field
				if(this.requestMode) {
					this.requestMode = false
					this.$nextTick(() => this.requestMode = true)
				}
				else this.requestMode = true
				this.requestJson = JSON.stringify(preset.request)
			}
			else {
				this.requestMode = false
				this.selectFields(preset.request.fields.map(f => f.name)).once(() => this.updatePreview())
			}
		},
		closeEdit() {
			this.editMode = null
			this.requestMode = false
			this.selectFields(this.defaultFields).once(() => this.updatePreview())
		},
		previewPreset(preset) {
			if(this.requestMode || this.disablePreview) return
			this.presetPreview = {preset: preset}
			let req = {
				data: JSON.parse(JSON.stringify(preset.request))
			}
			req.data.limit = 15
			Api.post('api/', req, {}, 'blmodelexporter').then(resp => {
				if(this.presetPreview) this.presetPreview.data = resp.data
			})
		},
		unpreviewPreset() {
			this.presetPreview = null
		},
		getPreview() {
			return this.presetPreview?.data ? this.presetPreview?.data : this.preview
		},
		getPreviewFields() {
			return this.presetPreview?.data ? this.presetPreview.preset.request.fields.map(f => {
				return {label: f.name, name: f.name}
			}) : this.selectedFields
		}
	}
}
</script>

<style scoped lang="scss">
	.preview {
		flex: 1;
		overflow: auto;;
		padding: 10px;

		table {
			pointer-events: none;
			width: auto;
			margin: auto;

			thead th {
				cursor: default;
			}

			tbody tr:hover {
				background-color: transparent;
			}
		}

		.gradient {
			background: linear-gradient(transparent, var(--bl-surface));
			height: 75px;
			margin: -74px auto auto;
			position: relative;
		}
	}

	h1 {
		font-family: 'Product sans';
		color: var(--bl-legend);
		margin: 0;
		padding: 5px;
		font-size: 18px;
		font-weight: 600;
	}

	h2 {
		display: flex;
		align-items: center;
		font-weight: normal;
		font-family: 'Product sans';
		font-size: 16px;

		icon {
			font-size: 18px;
			margin: 0 5px 0 10px;
			color: var(--bl-legend);
		}
	}

	.formatList {
		list-style-type: none;
		margin: 0;
		padding: 0 0 0 5px;

		li {
			user-select: none;
			margin: 0;
			padding: 5px 10px;
			display: flex;
			align-items: center;
			cursor: pointer;
			border-radius: var(--bl-border-radius);
			transition: background-color .2s;

			img {
				margin-right: 5px;
			}

			span {
				flex: 1;
			}

			button.bl-icon-button {
				padding: 0;
				font-size: 16px;
				display: none;
			}
		}

		li.selected {
			background-color: var(--bl-selected-bg);
			color: var(--bl-selected-fg);
			font-weight: 500;
		}

		li:hover:not(.selected) {
			background-color: var(--bl-background);
		}
		
		li:hover button.bl-icon-button, li button.bl-icon-button.alwaysShow {
			display: block;
		}
	}

	.presetPreviewTitle {
		th {
			padding: 0;

			div {
				padding: 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: normal;
			}
		}
	}
</style>