import { Registry } from 'InterfaceBundle'

/**
 * Style
 */
import './styles/files.scss'

/**
 * Components
 */
import BlFormFieldFile from './components/FormFieldFile.vue'
import BlFileView from './components/FileView.vue'
import BlMultiFileView from './components/MultiFileView.vue'
import BlFileViewDialog from './components/FileViewDialog.vue'
import BlFileExplorer from './components/FileExplorer.vue'

Registry.components(BlFormFieldFile, BlFileView, BlMultiFileView, BlFileViewDialog, BlFileExplorer)

/**
 * Directives
 */

/**
 * Services
 */
import { UploadHelpers as UploadHelpersService } from './services/UploadHelpers.js'

export const UploadHelpers = UploadHelpersService