<template>
	<slot></slot>
</template>

<script>
export default {
	name: 'BlTabBody'
}
</script>

<style scoped lang="scss">
</style>