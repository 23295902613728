<template>
	<div class="mainContainer" :style="{flexDirection: mobile ? 'column' : 'row', height: mainContainerHeight}">
		<InterfaceMainMenu v-if="!mobile" />
		<div class="interfaceRouterContainer" :class="transitionAnimation">
			<InterfaceDesignerToolbar v-if="showDesignerToolbar" :selfClose="toggleDesignerToolbar" />
			<InterfacePersistentMessages />
			<div class="interfaceContentContainer">
				<div class="splitviewContainer bl-light-scroll" :style="{width: splitview.width + 'px'}" v-if="getSplitview()" ref="splitviewContainer">
					<component :is="splitview.component" />
				</div>
				<div v-if="getSplitview()" :style="{marginLeft: splitview ? (splitview.width + 'px') : null}" v-bl-resize="{value: splitview.width, min: 150, max: 1200}" @resize="splitview.width = $event" style="height: calc(100% + 5px); position: fixed;"></div>

				<div class="currentRouteContainer" v-if="currentRoute" :style="{marginLeft: getSplitview() ? (splitview.width + 4 + 'px') : null}">
					<div class="topBarContainer bl-card" v-if="childData && currentRoute.options.showtopbar" :class="{selectMode: selectMode && selectMode.enabled, mobile: mobile}">
						<component v-if="!selectMode && currentRoute.actions.back" :is="{template:currentRoute.actions.back.template, data: childData}" />
						<h2 ref="title"><component :is="{template: $t(selectMode && selectMode.title ? selectMode.title : currentRoute.title), data: childData, updated: titleUpdated, mounted: titleUpdated}" /></h2>
						<BlUsersOnRoute v-if="router.showUsersOnRouteEnabled" style="margin-right: 15px;" />
						<template v-if="!mobile && !selectMode">
							<component v-for="action in currentRoute.actions.cta" :key="action.id" :is="{template:action.template, data: childData}" />
						</template>
						<component v-for="action in currentRoute.actions[selectMode ? 'select' : 'main']" :key="action.id" :is="{template:action.template, data: childData}" />
						<BlMenu v-if="currentRoute.actions.secondary.length && showSecondaryActions && !selectMode">
							<template #hook>
								<button class="bl-icon-button" type="button" v-bl-tooltip="$t('navigation.moreActions')">more_vert</button>
							</template>
							<ul style="width: 200px;">
								<component v-for="action in currentRoute.actions.secondary" :key="action.id" :is="{template:action.template, data: childData}" />
							</ul>
						</BlMenu>
					</div>
					<component ref="routeComponent" :is="currentRoute.component"></component>
				</div>
				<BlInterfaceNotFound v-if="!currentRoute && !transition" />
			</div>
		</div>
		<div v-if="currentRoute && childData && mobile && currentRoute.options.showtopbar && !selectMode" class="mobileCTAContainer">
			<component v-for="action in currentRoute.actions.cta" :key="action.id" :is="{template:action.template, data: childData}" />
		</div>
		<BlInterfaceSidebar v-if="false && !mobile" />
		<InterfaceMobileMenu v-if="mobile && showMobileMenu" />
		<InterfaceWindows v-if="!mobile" />
		<BlSnackbarContainer />
		<!-- Secondary action template to check whether to show menu, used with mutation observer -->
		<div ref="secondaryActionsContainer" style="display: none;">
			<template v-if="currentRoute && childData"><component v-for="action in currentRoute?.actions.secondary" :key="action.id" :is="{template:action.template, data: childData}" /></template>
		</div>
	</div>
</template>

<script>
import { Variables, Router, Dialog } from 'InterfaceBundle'
import BlInterfaceSidebar from './InterfaceSidebar'

/**
 * Default interface container
 */
export default {
	name: 'InterfaceRouterContainer',
	data() {
		return {
			currentRoute: null,
			transition: false,
			childData: null,
			isOffline: false,
			showMobileMenu: true,
			mainContainerHeight: null,
			showSecondaryActions: true,
			realtimeSub: null,
			router: Router,
			splitview: null,
			selectMode: null,
			transitionAnimation: 'same',
			showDesignerToolbar: localStorage.getItem('Interface.showDesignerToolbar') ? true : false,
			getSplitview: () => this.splitview && !Variables.mobile,
			mobile: Variables.mobile
		}
	},
	components: {
		BlInterfaceSidebar
	},
	created() {
		Router.selectModeChange.subscribe(e => this.selectMode = e)

		Router.change.subscribe(() => {
			this.transition = true
			this.currentRoute = null
			this.childData = null
			//nextTick to force component rerendering
			this.$nextTick(() => {
				document.querySelector('.interfaceRouterContainer').scrollTop = 0
				if(Router.currentRoute) this.transitionAnimation = Variables.mobile ? Router.currentRoute.getAnimation() : null
				setTimeout(() => this.transitionAnimation = null, 200)
				this.currentRoute = Router.currentRoute ? Router.currentRoute.definition : null
				this.transition = false

				//Set child data
				if(this.currentRoute) this.$nextTick(() => {
					this.childData = () => {
						let childDataContent = this.$refs.routeComponent._.data
						for(let key in this.$refs.routeComponent) childDataContent[key] = this.$refs.routeComponent[key]
						return childDataContent
					}
				})

				let previousSplitviewWidth = null
				if(this.splitview) previousSplitviewWidth = this.splitview.width
				if(this.currentRoute && this.currentRoute.options.splitview) {
					this.splitview = {
						component: this.currentRoute.options.splitview,
						width: previousSplitviewWidth ? previousSplitviewWidth : window.innerWidth * 0.3
					}
				}
				else this.splitview = null

			})
		})
		Router.initialize(this.$t)

		//Virtual keyboard handling
		if('virtualKeyboard' in navigator) {
			navigator.virtualKeyboard.addEventListener('geometrychange', this.virtualKeyboardHandler)
			navigator.virtualKeyboard.overlaysContent = true
		}
	},
	unmounted() {
		document.title = Variables.name
		if('virtualKeyboard' in navigator) {
			navigator.virtualKeyboard.removeEventListener('geometrychange', this.virtualKeyboardHandler)
			navigator.virtualKeyboard.overlaysContent = true
		}
		if(this.secondaryActionsObserver) this.secondaryActionsObserver.disconnect()

		window.removeEventListener('keydown', this.handleKeyboardShortcuts)
	},
	methods: {
		titleUpdated() {
			if(this.$refs.title?.innerText) document.title = this.$refs.title.innerText + ' - ' + Variables.name
		},
		virtualKeyboardHandler(event) {
			let height = event.target.boundingRect.height
			if(height) {
				this.showMobileMenu = false
				this.mainContainerHeight = 'calc(100% - ' + (height * 0.1965 + 181.3) + 'px)'
			}
			else {
				this.showMobileMenu = true
				this.mainContainerHeight = null
			}
		},
		toggleDesignerToolbar() {
			this.showDesignerToolbar = !this.showDesignerToolbar
			if(this.showDesignerToolbar) localStorage.setItem('Interface.showDesignerToolbar', true)
			else localStorage.removeItem('Interface.showDesignerToolbar')
		},
		handleKeyboardShortcuts(event) {
			if(event.ctrlKey && event.keyCode == 71 && !this.shortcutOpened) {
				this.shortcutOpened = true
				Dialog.custom({
					component: 'BlInterfaceShortcut',
					fullScreen: true,
					closeButton: false,
					class: 'blInterfaceShortcutDialogContainer'
				}).then(() => {}).catch(() => this.shortcutOpened = false)
				event.preventDefault()
				return false
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.secondaryActionsObserver = new MutationObserver(() => {
				this.showSecondaryActions = this.$refs.secondaryActionsContainer.querySelectorAll('*').length
			})
			this.secondaryActionsObserver.observe(this.$refs.secondaryActionsContainer, {childList: true})

			window.addEventListener('keydown', this.handleKeyboardShortcuts)
		})
	}
}
</script>

<style scoped lang="scss">
.interfaceRouterContainer {
	height: calc(100% - 5px);
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 100%;
	overflow: auto;
	flex: 1;
}

.topBarContainer {
	display: flex;
	height: 44px;
	min-height: 44px;
	align-items: center;
	transition: color .2s, background-color .2s;

	h2 {
		font-family: 'Product sans';
		margin: 0;
		padding: 0;
		flex: 1 1 0%;
		padding: 0 10px;
		font-size: 21px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	button:not(.bl-icon-button) {
		font-weight: normal;
		font-family: Roboto;
		height: 34px;
		min-width: 0;
		padding: 0 12px 0 8px;
		margin: 0 5px;
	}

}

:deep .topMenuButtonSecondary {
	background-color: transparent;
	border: 0;
	display: flex;
	align-items: center;
	max-width: 220px;
	width: 100%;
	height: 40px;
	padding-left: 15px;
	cursor: pointer;

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: 10px;
	}
}

.topBarContainer.selectMode {
	background-color: var(--bl-selected-bg);
	color: var(--bl-selected-fg);

	h2 {
		font-weight: normal;
		font-size: 16px;
	}

	.selectAction {
		background-color: var(--bl-selected-bg);
		color: var(--bl-selected-fg);
	}
}

.splitviewContainer {
	height: calc(100% + 0px);
	padding-bottom: 5px;
	overflow: auto;
	position: fixed;
}

.mobileCTAContainer button.callToAction {
	position: absolute;
	top: calc(100% - 153px - (var(--bl-snackbar-count) * 60px));
	left: calc(100% - 80px);
	z-index: 10;
	height: 60px;
	width: 60px;
	min-width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0;
	box-shadow: 1px 4px 18px 0px rgb(0 0 0 / 64%);
	transition: top .2s;
}

.mobileCTAContainer button.callToAction:deep span {
	display: none;
}

.mobileCTAContainer button.callToAction:deep icon {
	margin: 0;
	float: none;
	font-size: 30px;
}

.currentRouteContainer {
	flex: 1 1 0%;
	display: flex;
	flex-direction: column;
	max-width: 100%;
}

.interfaceContentContainer {
	display: flex;
	min-height: 100%;
}

.interfaceRouterContainer:is(.forward, .backward, .same) {
	overflow: hidden;
}

.interfaceRouterContainer.forward .interfaceContentContainer {
	animation: forward .2s;
}

@keyframes forward {
	0% {
		transform: translateX(30px);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.interfaceRouterContainer.backward .interfaceContentContainer {
	animation: backward .2s;
}

@keyframes backward {
	0% {
		transform: translateX(-30px);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.interfaceRouterContainer.same .interfaceContentContainer {
	animation: same .2s;
}

@keyframes same {
	0% {
		transform: scale(0.95);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}
</style>