<template>
	<template v-for="(item, index) in field.value" :key="item">
		<BlViewFields :name="isNew(item.key) ? null : field.name" :modelId="item.key">
			<BlFormFieldCollectionItem :field="item" :index="index"><slot :form="item" :index="index"></slot></BlFormFieldCollectionItem>
		</BlViewFields>
	</template>
</template>

<script>
export default {
	name: 'BlFormFieldCollection',
	inject: ['blForm'],
	props: ['field'],
	provide() {
		return {
			blFormCollectionValue: this.field
		}
	},
	methods: {
		isNew(id) {
			return id.substr(0, 4) == '_new'
		}
	},
	created() {
		if(this.field.options.autoAdd) {
			if(!this.field.autoAdd) {
				this.field.addValue(null, false)
				this.field.setAutoAdd(this.field.value[this.field.value.length - 1].key)
			}
			this.field.emitter.change.subscribe(() => {
				if(this.field.value[this.field.value.length - 1] && this.field.value[this.field.value.length - 1].getTouched()) {
					this.field.addValue()
					this.field.setAutoAdd(this.field.value[this.field.value.length - 1].key)
				}
			})
			this.blForm.emitter.beforeValidation.subscribe(() => {
				if(
					typeof this.field.value[this.field.value.length - 1] !== 'undefined' &&
					!this.field.value[this.field.value.length - 1].getTouched() &&
					this.field.autoAdd &&
					this.field.value.map(v => v.key).includes(this.field.autoAdd) &&
					(!this.field.options.required || this.field.value.length > 1)
				) {
					this.field.removeValue(this.field.value.map(v => v.key).indexOf(this.field.autoAdd))
					this.field.setAutoAdd(false)
				}
			})
		}
		else if(this.field.options.required && !this.field.value.length) this.field.addValue(null, false)
	}
}
</script>

<style scoped>
</style>