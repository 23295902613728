<template>
	<div class="blTabHeaderContainer"><slot></slot></div>
</template>

<script>
export default {
	name: 'BlTabHeader'
}
</script>

<style scoped lang="scss">
	div {
		display: none;
	}
</style>