import { Registry } from 'InterfaceBundle'

/**
 * Style
 */

/**
 * Components
 */
import BlDocumentFormPreview from './components/DocumentFormPreview.vue'
import BlDocumentDialogPreview from './components/DocumentDialogPreview.vue'
import BlDocumentPrintLoading from './components/DocumentPrintLoading.vue'

Registry.components(BlDocumentFormPreview, BlDocumentDialogPreview, BlDocumentPrintLoading)

/**
 * Directives
 */

/**
 * Services
 */
import { Document as DocumentService } from './services/Document.js'

export const ViewLoader = DocumentService